// Define allowed log levels for each environment
const ENVIRONMENTS = {
  development: ['debug', 'info', 'warn', 'error'],
  staging: ['info', 'warn', 'error'],
  production: ['warn', 'error'],
};

// Get the current environment from the environment variable, default to 'development'
const currentEnv = import.meta.env.MODE;

// Get the allowed log levels for the current environment
const allowedLevels = ENVIRONMENTS[currentEnv];

// Logger object with methods for each log level
const logger = {
  debug: (message, ...args) => {
    if (allowedLevels.includes('debug')) {
      console.log(`[DEBUG]: ${message}`, ...args);
    }
  },
  info: (message, ...args) => {
    if (allowedLevels.includes('info')) {
      console.info(`[INFO]: ${message}`, ...args);
    }
  },
  warn: (message, ...args) => {
    if (allowedLevels.includes('warn')) {
      console.warn(`[WARN]: ${message}`, ...args);
    }
  },
  error: (message, ...args) => {
    if (allowedLevels.includes('error')) {
      console.error(`[ERROR]: ${message}`, ...args);
    }
  },
};

export default logger;
