import { observer } from "mobx-react-lite";
import OverlayChess from "@/game/chess/OverlayChess";
import OverlayConnectFour from "@/game/connectFour/OverlayConnectFour";
import OverlayPinball from "@/game/pinball/OverlayPinball";
import OverlaySudoku from "@/game/sudoku/OverlaySudoku";
import OverlayTicTacToe from "@/game/ticTacToe/OverlayTicTacToe";
import OverlayTopDownCar from "@/game/topDownCar/OverlayTopDownCar";
import OverlayTutorial from "@/game/tutorial/OverlayTutorial";
import useGameStore from "@/store/useGameStore";

const Overlay = observer(() => {
  const {roomName} = useGameStore();
  switch (roomName) {
    case "chess":
      return <OverlayChess/>;
    case "connectFour":
      return <OverlayConnectFour/>;
    case "pinball":
      return <OverlayPinball/>;
    case "sudoku":
      return <OverlaySudoku/>;
    case "ticTacToe":
      return <OverlayTicTacToe/>
    case "topDownCar":
      return <OverlayTopDownCar/>
    case "tutorial":
      return <OverlayTutorial/>
    default:
      return null;
  }
});
export default Overlay;
