import {GameObjects, Geom} from "phaser";
import {
    COLOR_CANDIDATE_DIGIT,
    COLOR_CELL,
    COLOR_CELL_BORDER,
    COLOR_CELL_DIGIT,
    COLOR_CELL_DIGIT_INVALID,
    COLOR_CELL_GIVEN,
    COLOR_CELL_SELECTED,
    SIZE_CANDIDATE,
    SIZE_CELL
} from "@/game/sudoku/const";

export class Cell extends GameObjects.Container {
    type = 'cell';
    digit;
    valid = true;
    given = false;
    index;
    candidateTextList = new Array(9);
    candidateList = new Array(9).fill(false);
    text;

    constructor(scene, x, y, index) {
        super(scene, x, y);
        this.graphics = this.scene.add.graphics();
        this.index = index;
        this.selected = false;
        this.index = index;
        this.add(this.graphics);
        this.text = this.scene.add.text(
            SIZE_CELL / 2,
            SIZE_CELL / 2 + 4,
            this.digit || null,
            {fontSize: SIZE_CELL, color: COLOR_CELL_DIGIT}).setOrigin(.5);
        this.add(this.text);

        for (let i = 0; i < 9; i++) {
            const candidate = this.scene.add.text(
                SIZE_CANDIDATE * (i % 3) + SIZE_CANDIDATE / 2,
                SIZE_CANDIDATE * Math.floor(i / 3) + SIZE_CANDIDATE / 2,
                String(i + 1),
                {fontSize: SIZE_CANDIDATE, color: COLOR_CANDIDATE_DIGIT}).setOrigin(.5);
            this.add(candidate);
            this.candidateTextList[i] = candidate;
        }

        this.setInteractive(new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL), Geom.Rectangle.Contains);
        this.draw();
        this.scene.add.existing(this);
    }

    reset() {
        this.digit = null;
        this.valid = true;
        this.candidateList = new Array(9).fill(false)
    }

    draw() {
        if (this.digit) {
            this.text.setVisible(true);
            const digitColor = this.valid ? COLOR_CELL_DIGIT : COLOR_CELL_DIGIT_INVALID
            this.text.setColor(digitColor);
            this.text.setText(this.digit);
            for (let i = 0; i < 9; i++) {
                this.candidateTextList[i].setVisible(false);
            }
        } else {
            this.text.setVisible(false);
            for (let i = 0; i < 9; i++) {
                this.candidateTextList[i].setVisible(this.candidateList[i]);
            }
        }

        this.graphics.clear();
        this.graphics.lineStyle(1, COLOR_CELL_BORDER);
        let colorCell = this.selected ? COLOR_CELL_SELECTED : COLOR_CELL;
        if (this.given) {colorCell = COLOR_CELL_GIVEN;}
        this.graphics.fillStyle(colorCell);
        const r = new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL);
        this.graphics.fillRectShape(r);
        this.graphics.strokeRectShape(r);
    }

    setSelected(selected) {
        this.selected = selected;
        this.draw();
    }

    setDigit(digit) {
        this.digit = digit;
        this.draw();
    }

    setCandidate(digit, enabled) {
        const value = enabled ? enabled : !this.candidateList[digit - 1];
        this.candidateList[digit - 1] = value;
        this.draw();
    }

    setGiven(given) {
        this.given = given;
    }

    setValid(valid) {
        this.valid = valid;
    }
}
