import React from 'react';
import useModalStore from "@/store/useModalStore";

const ButtonConfirm = ({
                         children,
                         disabled = false,
                         onClick,
                         color = "primary",
                         text,
                         variant = "outlined"
                       }) => {
  const {
    setModalConfirmButtonText,
    setModalConfirmFunction,
    openModalConfirm
  } = useModalStore();

  return (
    <button
      className={`btn ${color === "error" ? 'btn-error' : 'btn-primary'} fill-primary hover:fill-white font-bold text-white ${variant !== 'contained' && 'btn-outline'}`}
      disabled={disabled}
      onClick={() => {
        setModalConfirmButtonText(text);
        setModalConfirmFunction(onClick);
        openModalConfirm();
      }}
    >
      {children}
    </button>
  );
};

export default ButtonConfirm;
