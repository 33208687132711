import { Button, CloseButton, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from "@heroicons/react/24/solid";
import { observer } from "mobx-react-lite";
import React from "react";
import useModalStore from "@/store/useModalStore";
import AlertSvg from '@/svg/alert.svg';

const ConfirmModal = observer(() => {
  const {
    modalConfirmOpen,
    closeModalConfirm,
    modalConfirmButtonText,
    modalConfirmFunction
  } = useModalStore();
  return (
    <Dialog
      as="div"
      className="relative z-10"
      onClose={closeModalConfirm}
      open={modalConfirmOpen}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            className="bg-white rounded-lg border border-primary shadow"
            transition
          >
            <DialogTitle
              as="div"
              className="relative p-1 flex bg-primary rounded-t-lg"
            >
              <AlertSvg
                fill="white"
                width={16}
              />
              <div className="ml-1 text-white font-bold font-md">Bestätigung</div>
              <CloseButton className="absolute right-1">
                <XMarkIcon
                  fill="white"
                  width={24}
                />
              </CloseButton>
            </DialogTitle>

            <div className="flex p-1 gap-1">
              <button
                className="btn btn-primary btn-outline"
                onClick={closeModalConfirm}
              >
                Schließen
              </button>
              <Button
                className="btn btn-error"
                onClick={() => {
                  modalConfirmFunction();
                  closeModalConfirm();
                }}
              >
                {modalConfirmButtonText}
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
})

export default ConfirmModal;
