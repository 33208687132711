import { Scene } from "phaser";
import colors from "tailwindcss/colors";
import { EventBus } from "@/EventBus";
import { ChessBoard } from "@/game/chess/ChessBoard";
import { BLACK, BOARD_SIZE, PIECE_COLOR_ATLAS, PIECE_SYMBOL_ATLAS, SQUARE_SIZE, WHITE } from "@/game/chess/const";
import { Piece } from "@/game/chess/Piece";
import { boardPositionToSquare } from "@/game/chess/util";
import { hexToInt } from "@/util/util";

export class SceneChess extends Scene {
  chess;
  safeAreaWidth = 900;
  safeAreaHeight = 1024;
  pieces = new Array(64);

  constructor() {
    super({
      key: 'chess'
    });
  }

  preload() {
    EventBus.emit("window-resize");
    this.load.atlas('chess-pieces-atlas', 'assets/chess/chess-pieces-atlas.png', 'assets/chess/chess-pieces-atlas.json')
  }

  syncPiece(colIndex, rowIndex, symbol) {
    let currentPiece = this.pieces[colIndex + 8 * rowIndex];
    if (currentPiece && (!symbol || currentPiece.symbol !== symbol)) {
      currentPiece.destroy();
      currentPiece = null;
      this.pieces[colIndex + 8 * rowIndex] = null;
    }
    if (symbol && !currentPiece) {
      const pieceScaleFactor = 40 / SQUARE_SIZE;
      const pieceColor = symbol.toUpperCase() === symbol ? WHITE : BLACK;
      const pieceSymbol = symbol.toLowerCase();
      const frame = `${PIECE_COLOR_ATLAS[pieceColor]}_${PIECE_SYMBOL_ATLAS[pieceSymbol]}.png`;
      const piece = new Piece(this, colIndex * SQUARE_SIZE + SQUARE_SIZE / 2, rowIndex * SQUARE_SIZE + SQUARE_SIZE / 2, 'chess-pieces-atlas', frame, colIndex, rowIndex, symbol).setScale(pieceScaleFactor);
      this.chessBoard.add(piece);
      this.pieces[colIndex + 8 * rowIndex] = piece;
    }
  }

  create() {
    this.room = this.registry.get('room');
    this.buttonNewGame = this.add.container(this.safeAreaWidth / 2 - 4, BOARD_SIZE + 180)
      .add(this.add.nineslice(0, 0, 'start', "button.svg", 500, 60)
        .setTint(hexToInt(colors.blue[200])).setInteractive().on("pointerdown", () => {
          this.room.send("reset")
        }))
      .add(this.add.text(0, 0, 'Neues Spiel', {
        fontFamily: 'Concert One',
        fontSize: SQUARE_SIZE / 2,
      }).setOrigin(0.5)).setVisible(false);
    this.infoText = this.add.text(this.safeAreaWidth / 2, BOARD_SIZE + 110, 'Waiting...', {
      fontFamily: 'Concert One',
      fontSize: SQUARE_SIZE / 2,
    })
      .setOrigin(0.5);
    this.chessBoard = new ChessBoard(this, this.safeAreaWidth / 2 - BOARD_SIZE / 2, 24);

    this.input.on('drag', (pointer, gameObject, dragX, dragY) => {
      gameObject.x = dragX;
      gameObject.y = dragY;
    })

    this.input.on('dragend', (pointer, gameObject, dropped) => {
      // console.logger('dragend', pointer, gameObject, dropped )
      if (!dropped) {
        this.tweens.add({
          targets: gameObject,
          duration: 1000,
          x: gameObject.input.dragStartX,
          y: gameObject.input.dragStartY,
          ease: 'Quad'
        })
      }
    })

    this.input.on('drop', (pointer, gameObject, target) => {
      const move = {
        from: boardPositionToSquare(gameObject),
        to: boardPositionToSquare(target)
      };
      this.room.send('move', move);
      this.tweens.add({
        targets: gameObject,
        duration: 1000,
        x: gameObject.input.dragStartX,
        y: gameObject.input.dragStartY,
        ease: 'Power2'
      })
    })
    this.room.state.board.onChange(() => {
      this.syncBoard();
    })
    this.room.state.listen("isCheckMate", () => this.updateInfoText())
    this.room.state.listen("isDraw", () => this.updateInfoText())
    this.room.state.listen("inCheck", () => this.updateInfoText())
    this.room.state.listen("turn", () => this.updateInfoText())
    this.room.state.listen("isGameOver", () => this.buttonNewGame.setVisible(this.room.state.isGameOver))
    this.updateInfoText();
    this.syncBoard();
  }

  updateInfoText() {
    const moveNumber = `Zug ${this.room.state.moveNumber}`;
    let extra = '';
    if (this.room.state.isCheckMate) {
      extra = ' Schachmatt';
    } else if (this.room.state.isDraw) {
      extra = ' Unentschieden';
    } else if (this.room.state.inCheck) {
      extra = ' Schach';
    }
    const turn = this.room.state.turn === 'w' ? " Weiß" : " Schwarz";
    this.infoText.setText(moveNumber + turn + extra)
  }

  syncBoard() {
    for (let i = 0; i < 64; i++) {
      this.syncPiece(i % 8, Math.floor(i / 8), this.room.state.board[i]);
    }
  }
}






