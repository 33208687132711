import {AUTO, Scale} from "phaser";
import {COLOR_GAME_BACKGROUND} from "@/config/const";

export const gameConfig = {
  parent: 'game-container',
  type: AUTO,
  backgroundColor: COLOR_GAME_BACKGROUND,
  scale: {
    width: window.innerWidth,
    height: window.innerHeight,
    mode: Scale.RESIZE,
    autoCenter: Scale.CENTER_BOTH,
  },
  audio: {
    disableWebAudio: true,
  },
  // scene: Object.values(sceneConfig)[0].class,
};
