import { Scene } from "phaser";
import { EventBus } from "@/EventBus";

export class SceneStart extends Scene {
  safeAreaWidth = 800;
  safeAreaHeight = 800;

  constructor() {
    super({
      key: 'start'
    });
  }

  preload() {
    EventBus.emit("window-resize");
    this.load.multiatlas('start', 'assets/start/start.json', 'assets/start');
    this.load.image('lobby', 'assets/start/lobby.jpg');
  }

  create() {
    this.add.image(this.safeAreaWidth / 2, this.safeAreaHeight / 2, 'lobby');
    // this.add.text(this.safeAreaWidth / 2, this.safeAreaHeight / 2, 'Start')
  }

  update() {

  }
}




