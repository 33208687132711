import colors from "tailwindcss/colors";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "@/../tailwind.config";

export const fullConfig = resolveConfig(tailwindConfig)
export const COLOR_GAME_BACKGROUND = colors.blue[200];


const myInitObject = {}
export default myInitObject;
window.myInitObject = myInitObject;
