import {SIZE_BLOCK, SIZE_CANDIDATE, SIZE_CELL} from "@/game/sudoku/const";

export const findColBlock = index => index % 3;
export const findRowBlock = index => Math.floor(index / 3);
export const findPositionBlockX = index => findColBlock(index) * SIZE_BLOCK;
export const findPositionBlockY = index => findRowBlock(index) * SIZE_BLOCK;

export const findColCandidate = index => index % (9 * 3);
export const findRowCandidate = index => Math.floor(index / (9 * 3));
export const findPositionCandidateX = index => findColCandidate(index) * SIZE_CANDIDATE + SIZE_CANDIDATE / 2;
export const findPositionCandidateY = index => findRowCandidate(index) * SIZE_CANDIDATE + SIZE_CANDIDATE / 2;

export const findColCell = index => index % (9);
export const findRowCell = index => Math.floor(index / (9));
export const findPositionCellX = index => findColCell(index) * SIZE_CELL;
export const findPositionCellY = index => findRowCell(index) * SIZE_CELL;
