import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share"
import useClipboard from "react-use-clipboard";
import colors from "tailwindcss/colors";
import Avatar from "@/components/Avatar";
import Profile from "@/components/Profile";
import RoomList from "@/components/RoomList";
import { sceneConfig } from "@/config/sceneConfig";
import useGameStore from "@/store/useGameStore";
import ClipboardSvg from "@/svg/clipboard.svg"
import useWindowSize from "@/util/useWindowSize";


const Lobby = observer(() => {
  const {windowHeight} = useWindowSize();
  const {
    allRooms,
    createRoom,
    joinRoomById,
    roomName,
    roomId,
    players,
    showLobby,
    setShowLobby
  } = useGameStore();

  const title = roomName ? sceneConfig[roomName].name : null;

  const shareTitle = "Spiel mit mir";

  const size = 100; // {sm: 100, md: 120, lg: 140, xl: 160, '2xl': 100,}[breakPoint];

  const shareUrl = `${import.meta.env.VITE_WEB_URL}?room=${roomId}`;
  const [isCopied, setCopied] = useClipboard(shareUrl);
  const shareButtonSize = 24;

  return (
    <div
      className={classNames("pointer-events-none fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center",
        {hidden: !showLobby}
      )}
    >
      <div
        className="pb-3 glass rounded pointer-events-auto flex flex-col"
        style={{maxHeight: windowHeight - 120}}
      >
        <div className="flex justify-end">
          <button onClick={() => setShowLobby(false)}>
            <XMarkIcon className="size-5 fill-primary"/>
          </button>
        </div>
        {!!title && (
          <div className="text-primary text-2xl text-center zoom-in-out-element select-none">
            {title}
          </div>
        )}
        {!!roomId && <div className="text-primary text-center">{`Raum: ${roomId}`}</div>}
        <div className="px-3 flex flex-col items-center gap-2 overflow-y-auto overflow-x-hidden">
          <Profile/>
          <div className="w-full flex flex-col">
            {
              [...players.keys()].sort().map(playerNumber => {
                const player = players.get(playerNumber);
                return (
                  <div
                    className="flex items-center justify-between"
                    key={playerNumber}
                  >
                    {`${playerNumber} ${player.name}`}
                    <Avatar
                      avatarId={player.avatarId ? player.avatarId : player.name}
                      size={32}
                    />
                  </div>
                )
              })
            }
          </div>
          {!!players && players.size === 1 ? <>
            <div className="text-primary text-xl">Spieler einladen</div>
            <div style={{
              width: size,
              height: size
            }}
            >
              <QRCodeSVG
                bgColor="transparent"
                size={size}
                value={shareUrl}
              />
            </div>
            <div className="mt-1 flex flex-wrap gap-1 pointer-events-auto">
              <WhatsappShareButton
                title={shareTitle}
                url={shareUrl}
              >
                <WhatsappIcon
                  round
                  size={shareButtonSize}
                />
              </WhatsappShareButton>
              <FacebookShareButton
                title={shareTitle}
                url={shareUrl}
              >
                <FacebookIcon
                  round
                  size={shareButtonSize}
                />
              </FacebookShareButton>
              <TwitterShareButton
                title={shareTitle}
                url={shareUrl}
              >
                <XIcon
                  round
                  size={shareButtonSize}
                />
              </TwitterShareButton>
              <LinkedinShareButton
                title={shareTitle}
                url={shareUrl}
              >
                <LinkedinIcon
                  round
                  size={shareButtonSize}
                />
              </LinkedinShareButton>
              <EmailShareButton
                title={shareTitle}
                url={shareUrl}
              >
                <EmailIcon
                  round
                  size={shareButtonSize}
                />
              </EmailShareButton>
              <div
                className="tooltip hover:tooltip-open  tooltip-primary"
                data-tip={isCopied ? 'Copied!' : 'Copy to Clipboard'}
              >
                <button
                  className="bg-primary rounded-full p-1 flex items-center justify-center"
                  onClick={setCopied}
                  style={{
                    width: shareButtonSize,
                    height: shareButtonSize
                  }}
                >
                  <ClipboardSvg
                    fill={colors.white}
                    height={shareButtonSize * .5}
                    width={shareButtonSize * .5}
                  />

                </button>
              </div>
            </div>
          </> : null}
          {!!roomId && (
            <button
              className="w-full pointer-events-auto btn btn-primary"
              onClick={() => {
                setShowLobby(!showLobby)
              }}
            >
              Spielen
            </button>
          )}

          <div className="w-full flex flex-col">
            <div className="text-primary text-center">Spiele</div>
            {
              Object.keys(sceneConfig).map(sceneKey => (
                <button
                  className={`px-1 my-0.5 rounded hover:bg-gray-200 text-left ${roomName === sceneKey && 'bg-blue-50'}`}
                  key={sceneKey}
                  onClick={() => createRoom(sceneKey)}
                >
                  {sceneConfig[sceneKey].name}
                </button>)
              )
            }
            <div className="mt-1 text-primary text-center">Räume</div>
            {allRooms.map(room => (
              <button
                className={`px-1 my-0.5 rounded flex flex-col hover:bg-gray-200 text-left ${roomId === room.roomId && 'bg-blue-50'}`}
                key={room.roomId}
                onClick={() => joinRoomById(room.roomId)}
              >
                {`${sceneConfig[room.name].name} ${room.clients}/${room.maxClients} ${room.roomId}`}
              </button>
            ))}
          </div>


        </div>

      </div>
    </div>
  )
})

export default Lobby;
