export const GAME_ASSETS = {
  RED_PIECE: 'RED_PIECE',
  YELLOW_PIECE: 'YELLOW_PIECE',
  BOARD: 'BOARD',
  DANCING_SCRIPT_FONT: 'DANCING_SCRIPT_FONT',
};

export const SCENE_KEYS = {
  PRELOAD: 'PRELOAD',
  TITLE: 'TITLE',
  DEMO: 'DEMO',
  GAME: 'GAME',
};

export const FRAME_SIZE = 128;
export const GAME_HEIGHT = FRAME_SIZE * 7;
export const GAME_WIDTH = FRAME_SIZE * 7;

export const GAME_STATE = {
  WAITING_FOR_PLAYERS: 'WAITING_FOR_PLAYERS',
  PLAYING: 'PLAYING',
  FINISHED: 'FINISHED',
};

export const CUSTOM_GAME_EVENTS = {
  GAME_PIECE_ADDED: 'GAME_PIECE_ADDED',
  NEW_GAME_STARTED: 'NEW_GAME_STARTED',
  EXISTING_GAME: 'EXISTING_GAME',
};
