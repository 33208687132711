import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from '@/App'
import '@/index.css'

// init({
//   dsn: "https://593fa22eb1ea1a4acac324ef0b1dd05a@o1241717.ingest.us.sentry.io/4508704910671872",
//   environment: import.meta.env.MODE,
//   release: import.meta.env.VITE_VERSION_NAME,
//   integrations: [
//     browserTracingIntegration(),
//     replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/game.andreasgoertzen\.eu\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//
// });

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App/>
  </StrictMode>,
)
