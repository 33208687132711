import {GameObjects} from "phaser";
import {COLOR_BLOCK_BORDER, SIZE_BLOCK} from "@/game/sudoku/const";

export class Block extends GameObjects.Container {
    constructor(scene, x, y) {
        super(scene, x, y);

        const graphics = this.scene.add.graphics();
        graphics.lineStyle(4, COLOR_BLOCK_BORDER);
        graphics.strokeRect(0, 0, SIZE_BLOCK, SIZE_BLOCK);
        this.add(graphics);

        this.scene.add.existing(this);
    }
}
