import InlineSVG from "react-inlinesvg";
import multiavatar from "@/util/multiavatar";

const Avatar = ({
                  avatarId = "Binx Bond",
                  size = 100
                }) => {
  const svgStringAvatar = multiavatar(avatarId, size);
  return (
    <InlineSVG src={svgStringAvatar}/>
  )
}

export default Avatar;
