import { GameObjects } from "phaser";
import {
  BOARD_SIZE,
  SQUARE_COLOR_WHITE,
  SQUARE_SIZE
} from "@/game/chess/const";
import { Square } from "@/game/chess/Square";

export class ChessBoard extends GameObjects.Container {
  scene;

  constructor(scene, x, y) {
    super(scene, x, y);
    this.scene = scene;
    for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
      for (let colIndex = 0; colIndex < 8; colIndex++) {
        const square = new Square(scene, colIndex, rowIndex);
        this.add(square);
      }
    }

    const labelFontStyle = {
      color: SQUARE_COLOR_WHITE,
      fontFamily: 'Concert One',
      fontSize: SQUARE_SIZE / 2,
      fontWeight: 'bold'
    };
    for (let i = 0; i < 8; i++) {
      this.add(scene.add.text(-SQUARE_SIZE * .25, i * SQUARE_SIZE + SQUARE_SIZE / 2, 8 - i, labelFontStyle).setOrigin(.5));
      this.add(scene.add.text(BOARD_SIZE + SQUARE_SIZE * .25, i * SQUARE_SIZE + SQUARE_SIZE / 2, 8 - i, labelFontStyle).setOrigin(.5));
      this.add(scene.add.text(i * SQUARE_SIZE + SQUARE_SIZE / 2, -SQUARE_SIZE * .35, String.fromCharCode(65 + i), labelFontStyle).setOrigin(.5));
      this.add(scene.add.text(i * SQUARE_SIZE + SQUARE_SIZE / 2, BOARD_SIZE + SQUARE_SIZE * .35, String.fromCharCode(65 + i), labelFontStyle).setOrigin(.5));
    }

    scene.add.existing(this);
  }
}
