import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { EventBus } from "@/EventBus";
import useGameStore from "@/store/useGameStore";

const OverlayPinball = observer(() => {
  const [showBlockGameOver, setShowBlockGameOver] = useState(false);
  const [showBlockWelcome, setShowBlockWelcome] = useState(true);
  const [finalScore, setFinalScore] = useState(false);

  useEffect(() => {
    EventBus.on('game-over-pinball', data => {
      setFinalScore(data.finalScore);
      setShowBlockGameOver(true);
    })
    return () => {
      EventBus.off('game-over-pinball');
    }
  }, []);
  return (
    <div className="pointer-events-none">
      <div className="fixed p-1  h-full w-full flex items-center justify-center gap-1">
        {(showBlockWelcome) ? <div
          className="card prose glass p-2"
        >

          <div className="text-3xl text-white">Welcome to Pinball!</div>
          <div className="text-2xl text-white">
            You play with
            <span
              className="text-yellow-500"
            >
              [ A ]
            </span>
            {' '}
            and
            <span
              className="text-blue-500"
            >
              [ D ]
            </span>
          </div>
          <div className="text-2xl text-white">
            Fire away with
            <span
              className="text-green-500"
            >
              [ Space ]
            </span>
          </div>
          <div className="text-2xl text-white">
            Press
            <span
              className="text-red-500"
            >
              [ P ]
            </span>
            {' '}
            to
            mute
            sound
          </div>
          <div className="text-2xl text-white">Click the button to start</div>
          <button
            className="pointer-events-auto btn btn-secondary mt-2"
            onClick={() => {
              setShowBlockWelcome(false);
              EventBus.emit('start-game');
            }}
          >
            Lets play!
          </button>

        </div> : null}
        {(showBlockGameOver) ? <div className="card glass p-2">
          <div className="text-3xl text-white">Game Over!</div>
          <div className="finalScore text-2xl text-white">
            Final
            Score:
            {finalScore}
          </div>
          <div className="text-2xl text-white">Click the button to play again</div>
          <button
            className="pointer-events-auto btn btn-secondary mt-2"
            onClick={() => {
              setShowBlockGameOver(false);
              EventBus.emit('game-over-again')
            }}
          >
            Lets play!
          </button>
        </div> : null}
      </div>
    </div>
  )
})

export default OverlayPinball;
