import { observer } from "mobx-react-lite";
import { EventBus } from "@/EventBus";
import useGameStore from "@/store/useGameStore";
import FullscreenSvg from '@/svg/fullscreen.svg';
import FullscreenExitSvg from '@/svg/fullscreen_exit.svg';


const ButtonFullscreen = observer(() => {
  const {fullscreen} = useGameStore();
  const handleClick = () => {
    if (fullscreen) {
      EventBus.emit('exit-fullscreen');
    } else {
      EventBus.emit('enter-fullscreen');
    }
  };
  return (
    <button
      className="pointer-events-auto btn btn-primary btn-outline fill-primary hover:fill-white"
      onClick={handleClick}
    >
      {!fullscreen && <FullscreenSvg className="size-5"/>}
      {fullscreen ? <FullscreenExitSvg className="size-5"/> : null}
    </button>
  )
})

export default ButtonFullscreen;
