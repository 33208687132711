import { EventBus } from "@/EventBus";
import { hexToInt } from "@/util/util";
import { Scene } from "phaser";
import colors from "tailwindcss/colors";

export class SceneTicTacToe extends Scene {
  safeAreaWidth = 1024;
  safeAreaHeight = 1240;
  infoText;
  room;

  constructor() {
    super({
      key: 'ticTacToe'
    });
  }

  preload() {
    EventBus.emit("window-resize");
    this.load.svg('o', 'assets/ticTacToe/o.svg');
    this.load.svg('x', 'assets/ticTacToe/x.svg');
  }

  create() {
    const CELL_SIZE = this.safeAreaWidth / 3;

    this.infoText = this.add.text(this.safeAreaWidth / 2, CELL_SIZE * 3 + 48, 'Moin', {
      fontFamily: 'Concert One',
      fontSize: '64px',
    }).setOrigin(0.5);

    this.piecesPlayerOne = [];
    this.piecesPlayerTwo = [];

    for (let i = 0; i < 9; i++) {
      const row = Math.floor(i / 3);
      const col = i % 3;
      const zone = this.add
        .rectangle(CELL_SIZE * col, CELL_SIZE * row, CELL_SIZE, CELL_SIZE, hexToInt(colors.gray[200]))
        .setOrigin(0, 0)
        .setData('col', col)
        .setData('row', row)
        .setInteractive()
        .on('pointerdown', () => {
          const row = zone.getData('row');
          const col = zone.getData('col');
          this.send('move', {
            row,
            col
          });
        });
      this.piecesPlayerOne.push(this.add.image(CELL_SIZE * col + CELL_SIZE / 2, CELL_SIZE * row + CELL_SIZE / 2, 'x')
        .setScale(2, 2)
        // .setDepth(1)
        .setVisible(false));
      this.piecesPlayerTwo.push(this.add.image(CELL_SIZE * col + CELL_SIZE / 2, CELL_SIZE * row + CELL_SIZE / 2, 'o')
        .setScale(2, 2)
        // .setDepth(1)
        .setVisible(false));
    }

    const grid = this.add.graphics();
    grid.lineStyle(24, 0x3e3e3e);
    grid.lineBetween(0, CELL_SIZE, CELL_SIZE * 3, CELL_SIZE);
    grid.lineBetween(0, CELL_SIZE * 2, CELL_SIZE * 3, CELL_SIZE * 2);
    grid.lineBetween(CELL_SIZE, 0, CELL_SIZE, CELL_SIZE * 3);
    grid.lineBetween(CELL_SIZE * 2, 0, CELL_SIZE * 2, CELL_SIZE * 3);

    this.buttonNewGame = this.add.container(this.safeAreaWidth / 2, CELL_SIZE * 3 + 140)
      .add(this.add.nineslice(0, 0, 'start', "button.svg", 600, 80)
        .setTint(hexToInt(colors.blue[200])).setInteractive().on("pointerdown", () => {
          this.send("reset")
        }))
      .add(this.add.text(0, 0, 'Neues Spiel', {
        fontFamily: 'Concert One',
        fontSize: '64px',
      }).setOrigin(0.5)).setVisible(false);
    this.room = this.registry.get('room');
    this.room.state.board.onChange((value, key) => {
      this.piecesPlayerOne[key].visible = value === 1;
      this.piecesPlayerTwo[key].visible = value === 2;
    })
    this.room.state.listen("playersTurn", () => {
      this.updateInfoText();
    })
    this.room.state.listen("winner", () => {
      this.buttonNewGame.visible = this.room.state.winner !== -1;
      this.updateInfoText();
    })
  }

  send(event, data) {
    if (this.room) {
      this.room.send(event, data)
    }
  }

  updateInfoText() {
    if (this.infoText) {
      if (this.room.state.winner === 1) {
        this.infoText.setText("Spieler 1 hat gewonnen");
      } else if (this.room.state.winner === 2) {
        this.infoText.setText("Spieler 2 hat gewonnen");
      } else if (this.room.state.winner === 0) {
        this.infoText.setText("Unentschieden");
      } else if (this.room.state.playersTurn === 1) {
        this.infoText.setText("Spieler 1 am Zug");
      } else if (this.room.state.playersTurn === 2) {
        this.infoText.setText("Spieler 2 am Zug");
      }
    }
  }
}




