import { observer } from "mobx-react-lite";
import useGameStore from "@/store/useGameStore";

const ButtonLobby = observer(() => {
  const {
    showLobby,
    setShowLobby
  } = useGameStore();
  const handleClick = () => {
    setShowLobby(!showLobby);
  }
  return (
    <button
      className="pointer-events-auto btn btn-outline btn-primary"
      onClick={handleClick}
    >
      Lobby
    </button>
  )
})
export default ButtonLobby;
