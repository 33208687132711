import ButtonConfirm from "@/components/buttons/ButtonConfirm";
import { observer } from "mobx-react-lite";
import useGameStore from "@/store/useGameStore";
import ReloadSvg from '@/svg/reload.svg';


const ButtonReset = observer(() => {
  const {send} = useGameStore();
  const handleClick = () => {
    send("reset");
  };
  return (
    <ButtonConfirm
      onClick={handleClick}
      text="Spiel neu starten"
    >
      <ReloadSvg className="size-5"/>
    </ButtonConfirm>
  )
})

export default ButtonReset
;
