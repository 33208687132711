import { observer } from "mobx-react-lite";
import React from "react";
import { sceneConfig } from "@/config/sceneConfig";
import useGameStore from "@/store/useGameStore";

const RoomList = observer(() => {
  const {
    roomId,
    allRooms,
    joinRoomById,
  } = useGameStore();

  return (
    <div className="flex flex-col">
      {allRooms.map(room => (
        <button
          className={`py-0.5 px-1 flex flex-col hover:bg-gray-200 text-left ${roomId === room.roomId && 'bg-blue-50'}`}
          key={room.roomId}
          onClick={() => joinRoomById(room.roomId)}
        >
          {`${sceneConfig[room.name].name} ${room.clients}/${room.maxClients} ${room.roomId}`}
        </button>
      ))}
    </div>
  )
})

export default RoomList;
