import {GameObjects, Geom} from "phaser";
import {
    COLOR_CELL,
    COLOR_CELL_BORDER,
    COLOR_CELL_DIGIT,
    COLOR_CELL_SELECTED, SIZE_CANDIDATE,
    SIZE_CELL
} from "@/game/sudoku/const";

export class ButtonModeCandidate extends GameObjects.Container {
    type = 'buttonModeCandidate';
    selected = false;
    graphics;
    constructor(scene, x, y) {
        super(scene, x, y);
        this.graphics = this.scene.add.graphics();
        this.add(this.graphics);

        for (let i = 0; i < 9; i++) {
            const text = this.scene.add.text(
                SIZE_CANDIDATE * (i % 3) + SIZE_CANDIDATE / 2,
                SIZE_CANDIDATE * Math.floor(i / 3) + SIZE_CANDIDATE / 2 + 2,
                i + 1,
                {fontSize: SIZE_CANDIDATE, color: COLOR_CELL_DIGIT}).setOrigin(.5);
            this.add(text);
        }
        this.setInteractive(new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL), Geom.Rectangle.Contains);
        this.draw();
        scene.add.existing(this);
    }

    setSelected(selected) {
        this.selected = selected;
        this.draw();
    }

    draw() {
        this.graphics.clear();
        this.graphics.lineStyle(1, COLOR_CELL_BORDER);
        this.graphics.fillStyle(this.selected ? COLOR_CELL_SELECTED : COLOR_CELL);
        const r = new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL);
        this.graphics.fillRectShape(r);
        this.graphics.strokeRectShape(r);
    }
}
