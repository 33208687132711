import { observer } from "mobx-react-lite";
import React from "react";
import ButtonFullscreen from "@/components/ButtonFullscreen";
import ButtonLobby from "@/components/ButtonLobby";
import Lobby from "@/components/Lobby";

const OverlayTicTacToe = observer(() => (
  <div className="pointer-events-none">
  </div>
))

export default OverlayTicTacToe;
