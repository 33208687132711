import { GameObjects } from 'phaser';

export class Piece extends GameObjects.Image {
  colIndex;
  rowIndex;
  symbol;

  constructor(scene, x, y, texture, frame, colIndex, rowIndex, symbol) {
    super(scene, x, y, texture, frame);
    this.colIndex = colIndex;
    this.rowIndex = rowIndex;
    this.symbol = symbol;
    this.setInteractive({
      draggable: true,
      useHandCursor: true
    });
    scene.add.existing(this);
  }
}
