import useGameStore from "@/store/useGameStore";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { EventBus } from "@/EventBus";
import useModalStore from "@/store/useModalStore";

const OverlaySudoku = observer(() => {
  const {send} = useGameStore();
  const {
    setModalConfirmButtonText,
    setModalConfirmFunction,
    openModalConfirm
  } = useModalStore();
  const handleChangeDifficulty = (difficulty) => {
    setModalConfirmButtonText("Spiel neu starten");
    setModalConfirmFunction(() => send("difficulty", difficulty));
    openModalConfirm();
  }
  useEffect(() => {
    EventBus.on('sudoku-difficulty', difficulty => handleChangeDifficulty(difficulty));
  }, [])

  return (
    <div className="pointer-events-none">
      <div className="fixed p-1 w-full flex justify-center gap-1">
        {/*<button*/}
        {/*  className="pointer-events-auto btn btn-sm"*/}
        {/*  onClick={() => handleGenerate(DIFFICULTY_EASY)}*/}
        {/*>*/}
        {/*  Easy*/}
        {/*</button>*/}
        {/*<button*/}
        {/*  className="pointer-events-auto btn btn-sm"*/}
        {/*  onClick={() => handleGenerate(DIFFICULTY_MEDIUM)}*/}
        {/*>*/}
        {/*  Medium*/}
        {/*</button>*/}
      </div>
    </div>
  )
})

export default OverlaySudoku;
