import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { EventBus } from "@/EventBus";

const OverlayTopDownCar = observer(() => {
  const [gameData, setGameData] = useState({});
  useEffect(() => {
    EventBus.on('game-data', data => setGameData({...gameData, ...data}));
  }, []);
  return (
    <div className="pointer-events-none">
      <div className="glass fixed p-1 w-full flex gap-1">
        <button
          className="pointer-events-auto btn btn-sm"
          onClick={e => {
            e.preventDefault();
            EventBus.emit('try');
          }}
        >
          Try
        </button>

      </div>
      <div className="fixed top-14 left-3 card bg-base-100 p-2 opacity-50">
        <pre>{JSON.stringify(gameData, null, 2)}</pre>
      </div>
    </div>
  )
})

export default OverlayTopDownCar;
