import {Display} from "phaser";
import colors from "tailwindcss/colors";

export const SQUARE_COLOR_BLACK = colors.blue[800];
export const SQUARE_COLOR_WHITE = '#fff';
export const SQUARE_COLOR_BLACK_INT =  Display.Color.HexStringToColor(SQUARE_COLOR_BLACK).color;
export const SQUARE_COLOR_WHITE_INT = Display.Color.HexStringToColor(SQUARE_COLOR_WHITE).color;
export const BOARD_SIZE = 800;
export const SQUARE_SIZE = BOARD_SIZE / 8;

export const WHITE = 'w'
export const BLACK = 'b'

export const PAWN = 'p'
export const KNIGHT = 'n'
export const BISHOP = 'b'
export const ROOK = 'r'
export const QUEEN = 'q'
export const KING = 'k'

export const PIECE_COLORS  = ['w', 'b'];
export const PIECE_SYMBOLS = ['p', 'n' , 'b' , 'r' , 'q' , 'k'];

export const PIECE_COLOR_ATLAS = {
    w: 'white',
    b: 'black',
}

export const PIECE_SYMBOL_ATLAS = {
    p: 'pawn',
    n: 'knight',
    b: 'bishop',
    r: 'rook',
    q: 'queen',
    k: 'king',
}
