import {GameObjects} from "phaser";

const roadSegments = [
    {p1: {x: 72, y: 175}, p2: {x: 263, y: 174}},
    {p1: {x: 263, y: 174}, p2: {x: 439, y: 236}},
    {p1: {x: 439, y: 236}, p2: {x: 1208, y: 434}},
    {p1: {x: 1208, y: 434}, p2: {x: 1060, y: 863}},
    {p1: {x: 1060, y: 863}, p2: {x: 208, y: 933}},
    {p1: {x: 208, y: 933}, p2: {x: -359, y: 738}},
    {p1: {x: -359, y: 738}, p2: {x: -557, y: 322.6}},
    {p1: {x: -557, y: 322.6}, p2: {x: -560, y: 91}},
    {p1: {x: -560, y: 91}, p2: {x: 72, y: 175}},
];

class Road extends GameObjects.Graphics {
    constructor(scene, options, segments, roadWidth) {
        super(scene, options);
        scene.add.existing(this);

        this.lineStyle(roadWidth, 0xbbbbbb);

        this.beginPath();
        for (const s of segments) {
            this.moveTo(s.p1.x, s.p1.y);
            this.lineTo(s.p2.x, s.p2.y);
        }
        this.strokePath();

        // const graphicsCorner = this.add.graphics({ x: 700, y: 400 });
        this.lineStyle(roadWidth/2 , 0xbbbbbb);
        for (const s of segments) {
            this.strokeCircle(s.p1.x, s.p1.y, (roadWidth/4))
        }
    }
}

export {
    Road,
    roadSegments
};

