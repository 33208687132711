import { Scene } from "phaser";
import colors from "tailwindcss/colors";
import { EventBus } from "@/EventBus";
import { FRAME_SIZE, GAME_HEIGHT } from "@/game/connectFour/constConnectFour"
import { hexToInt } from "@/util/util";

export class SceneConnectFour extends Scene {
  safeAreaWidth = 1024;
  safeAreaHeight = 1100;
  boardContainer;
  created = false;

  constructor() {
    super({
      key: 'connectFour',
    });
  }

  preload() {
    EventBus.emit("window-resize");
    this.load.image('board', 'assets/connectFour/board.png');
    this.load.image('red', 'assets/connectFour/red.png');
    this.load.image('yellow', 'assets/connectFour/yellow.png');
  }

  create() {
    this.buttonNewGame = this.add.container(this.safeAreaWidth / 2 - 4, GAME_HEIGHT + 148)
      .add(this.add.nineslice(0, 0, 'start', "button.svg", 500, 80)
        .setTint(hexToInt(colors.blue[200])).setInteractive().on("pointerdown", () => {
          this.room.send("reset")
        }))
      .add(this.add.text(0, 0, 'Neues Spiel', {
        fontFamily: 'Concert One',
        fontSize: 64,
      }).setOrigin(0.5)).setVisible(true);
    this.infoText = this.add.text(this.safeAreaWidth / 2, GAME_HEIGHT + 48, '', {
      fontFamily: 'Concert One',
      fontSize: '64px',
    }).setOrigin(0.5);

    this.boardContainer = this.add.container(0, 0);
    for (let col = 0; col < 7; col++) {
      const zone = this.add
        .rectangle(col * FRAME_SIZE + FRAME_SIZE / 2, 0, FRAME_SIZE, GAME_HEIGHT)
        .setOrigin(0, 0)
        .setInteractive()
        .on('pointerdown', () => {
          this.send('move', col);
        });
      this.boardContainer.add(zone);
    }
    this.piecesPlayerOne = [];
    this.piecesPlayerTwo = [];
    let piece;
    for (let i = 0; i < 42; i++) {
      const row = Math.floor(i / 7);
      const col = i % 7;
      piece = this.add.image((col + 1) * FRAME_SIZE, (row + 1) * FRAME_SIZE, 'red')
        .setVisible(false);
      this.boardContainer.add(piece);
      this.piecesPlayerOne.push(piece);
      piece = this.add.image((col + 1) * FRAME_SIZE, (row + 1) * FRAME_SIZE, 'yellow')
        .setVisible(false);
      this.boardContainer.add(piece);
      this.piecesPlayerTwo.push(piece);
    }

    this.boardContainer.add(this.add.image(0, 0, 'board').setOrigin(0, 0));

    this.room = this.registry.get('room');

    this.room.state.board.onChange((value, key) => {
      this.piecesPlayerOne[key].visible = value === 1;
      this.piecesPlayerTwo[key].visible = value === 2;
    })
    this.room.state.listen("playersTurn", () => {
      this.updateInfoText();
    })
    this.room.state.listen("winner", () => {
      this.buttonNewGame.visible = this.room.state.winner !== -1;
      this.updateInfoText();
    })
  }

  send(event, data) {
    if (this.room) {
      this.room.send(event, data)
    }
  }

  updateInfoText() {
    if (this.room.state.winner === 1) {
      this.infoText.setText("Spieler 1 hat gewonnen");
    } else if (this.room.state.winner === 2) {
      this.infoText.setText("Spieler 2 hat gewonnen");
    } else if (this.room.state.winner === 0) {
      this.infoText.setText("Unentschieden");
    } else if (this.room.state.playersTurn === 1) {
      this.infoText.setText("Spieler 1 am Zug");
    } else if (this.room.state.playersTurn === 2) {
      this.infoText.setText("Spieler 2 am Zug");
    }
  }

}




