import { Scene } from "phaser";
import colors from "tailwindcss/colors";
import { EventBus } from "@/EventBus";
import {
  DIFFICULTY_EASY,
  DIFFICULTY_HARD,
  DIFFICULTY_MEDIUM,
  SIZE_BLOCK,
  SIZE_BOARD,
  SIZE_CELL
} from "@/game/sudoku/const";
import { Puzzle } from "@/game/sudoku/Puzzle";
import { hexToInt } from "@/util/util";

export class SceneSudoku extends Scene {
  safeAreaWidth = 808;
  safeAreaHeight = 1160;

  constructor() {
    super({
      key: 'sudoku',
    });
  }

  preload() {
    EventBus.emit("window-resize");
    this.load.svg('undo', 'assets/sudoku/undo.svg', {scale: 0.5});
  }

  create() {
    this.room = this.registry.get('room');
    const puzzle = new Puzzle(this, 4, SIZE_CELL);
    this.buttonEasy = this.add.container(4, 0);
    this.buttonEasyImage = this.add.nineslice(4, 0, 'start', "button.svg", SIZE_BLOCK - 8, 80)
      .setOrigin(0, 0)
      .setInteractive().on("pointerdown", () => {
        EventBus.emit("sudoku-difficulty", DIFFICULTY_EASY);
      });
    this.buttonEasy.add(this.buttonEasyImage)
      .add(this.add.text(SIZE_BLOCK / 2, 40, 'Easy', {
        fontFamily: 'Concert One',
        fontSize: '64px',
      }).setOrigin(0.5, 0.5));
    this.buttonMedium = this.add.container(4 + SIZE_BLOCK, 0);
    this.buttonMediumImage = this.add.nineslice(4, 0, 'start', "button.svg", SIZE_BLOCK - 8, 80)
      .setOrigin(0, 0)
      .setInteractive().on("pointerdown", () => {
        EventBus.emit("sudoku-difficulty", DIFFICULTY_MEDIUM);
      });
    this.buttonMedium.add(this.buttonMediumImage)
      .add(this.add.text(SIZE_BLOCK / 2, 40, 'Medium', {
        fontFamily: 'Concert One',
        fontSize: '64px',
      }).setOrigin(0.5, 0.5));
    this.buttonHard = this.add.container(4 + 2 * SIZE_BLOCK, 0);
    this.buttonHardImage = this.add.nineslice(4, 0, 'start', "button.svg", SIZE_BLOCK - 8, 80)
      .setOrigin(0, 0)
      .setInteractive().on("pointerdown", () => {
        EventBus.emit("sudoku-difficulty", DIFFICULTY_HARD);
      });
    this.buttonHard.add(this.buttonHardImage)
      .add(this.add.text(SIZE_BLOCK / 2, 40, 'Hard', {
        fontFamily: 'Concert One',
        fontSize: '64px',
      }).setOrigin(0.5, 0.5));
    this.room.state.listen("hardBoardAvailable", () => {
      this.buttonHard.setVisible(this.room.state.hardBoardAvailable);
    })
    this.room.state.listen("difficulty", () => {
      this.buttonEasyImage.setTint(hexToInt(this.room.state.difficulty === DIFFICULTY_EASY ? colors.green[400] : colors.white));
      this.buttonMediumImage.setTint(hexToInt(this.room.state.difficulty === DIFFICULTY_MEDIUM ? colors.green[400] : colors.white));
      this.buttonHardImage.setTint(hexToInt(this.room.state.difficulty === DIFFICULTY_HARD ? colors.green[400] : colors.white));
    })

    this.buttonNewGame = this.add.container(this.safeAreaWidth / 2 - 4, SIZE_BOARD + 310)
      .add(this.add.nineslice(0, 0, 'start', "button.svg", 500, 80)
        .setTint(hexToInt(colors.blue[200])).setInteractive().on("pointerdown", () => {
          this.room.send("reset")
        }))
      .add(this.add.text(0, 0, 'Neues Spiel', {
        fontFamily: 'Concert One',
        fontSize: '64px',
      }).setOrigin(0.5)).setVisible(false);
    this.room.state.listen("solved", () => {
      this.buttonNewGame.setVisible(this.room.state.solved);
    })

    this.input.on('gameobjectdown', (pointer, gameObject) => {
      const type = gameObject.type;
      // logger.debug('gameobjectdown', gameObject);
      if (type === 'cell') {
        puzzle.selectCell(gameObject.index);
      } else if (type === 'buttonDigit') {
        puzzle.selectDigit(gameObject.digit);
      } else if (type === 'buttonModeCandidate') {
        puzzle.setModeCandidate(!puzzle.modeCandidate);
      } else if (type === 'buttonHint') {
        puzzle.hint();
      }
    })
  }

  resize() {
  }
}






