import {GameObjects, Geom} from "phaser";
import {
    COLOR_CELL,
    COLOR_CELL_BORDER,
    COLOR_CELL_DIGIT,
    COLOR_CELL_SELECTED, SIZE_CANDIDATE,
    SIZE_CELL
} from "@/game/sudoku/const";

export class ButtonDigit extends GameObjects.Container {
    type;
    modeCandidate = false;
    digit;
    selected = false;
    graphics;
    constructor(scene, x, y, digit, type = 'buttonDigit') {
        super(scene, x, y);
        this.type = type;
        this.digit = digit;
        this.graphics = this.scene.add.graphics();
        this.add(this.graphics);
        this.text = this.scene.add.text(
            SIZE_CELL / 2,
            SIZE_CELL / 2 + 4,
            digit,
            {fontSize: SIZE_CELL, color: COLOR_CELL_DIGIT}).setOrigin(.5);
        this.add(this.text);
        this.setInteractive(new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL), Geom.Rectangle.Contains);
        this.draw();
        scene.add.existing(this);
    }

    setSelected(selected) {
        this.selected = selected;
        this.draw();
    }

    setModeCandidate(modeCandidate) {
        this.modeCandidate = modeCandidate;
        this.draw();
    }

    draw() {
        if (this.modeCandidate) {
            const index = this.digit - 1;
            this.text.setFontSize(SIZE_CANDIDATE );
            this.text.setPosition(SIZE_CANDIDATE * (index % 3) + SIZE_CANDIDATE / 2,
                SIZE_CANDIDATE * Math.floor(index / 3) + SIZE_CANDIDATE / 2 + 2);
        } else {
            this.text.setFontSize( SIZE_CELL);
            this.text.setPosition(SIZE_CELL / 2,
                SIZE_CELL / 2 + 4);

        }
        this.graphics.clear();
        this.graphics.lineStyle(1, COLOR_CELL_BORDER);
        this.graphics.fillStyle(this.selected ? COLOR_CELL_SELECTED : COLOR_CELL);
        const r = new Geom.Rectangle(0, 0, SIZE_CELL, SIZE_CELL);
        this.graphics.fillRectShape(r);
        this.graphics.strokeRectShape(r);
    }
}
