import {GameObjects, Geom} from 'phaser';
import {SQUARE_COLOR_BLACK_INT, SQUARE_COLOR_WHITE_INT, SQUARE_SIZE} from "@/game/chess/const";

export class Square extends GameObjects.Container {
    colIndex;
    rowIndex;

    constructor(scene, colIndex, rowIndex) {
        super(scene, colIndex * SQUARE_SIZE, rowIndex * SQUARE_SIZE);
        this.colIndex = colIndex;
        this.rowIndex = rowIndex;
        const squareColor = (rowIndex + colIndex) % 2 === 0 ? SQUARE_COLOR_WHITE_INT : SQUARE_COLOR_BLACK_INT;
        this.rectangle = scene.add.rectangle(0, 0, SQUARE_SIZE, SQUARE_SIZE, squareColor).setOrigin(0);
        this.setInteractive(new Geom.Rectangle(0, 0, SQUARE_SIZE, SQUARE_SIZE), Geom.Rectangle.Contains, true);
        this.add(this.rectangle);
        scene.add.existing(this);
    }
}
