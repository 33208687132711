import {useEffect, useState} from "react";
import {fullConfig} from "@/config/const";

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const {screens} = fullConfig.theme;
  const breakPoint = Object.keys(screens).reverse().reduce((acc, key) => windowWidth < parseInt(screens[key]) ? [key, ...acc] : acc, ["2xl"])[0];
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return {
    breakPoint,
    windowWidth,
    windowHeight
  };
}

export default useWindowSize;
