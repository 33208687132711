import colors from "tailwindcss/colors";
import {hexToInt} from "@/util/util";

export const DIFFICULTY_EASY = "easy";
export const DIFFICULTY_MEDIUM = "medium";
export const DIFFICULTY_HARD = "hard";
export const DIFFICULTY_EXPERT = "expert";
export const DIFFICULTY_MASTER = "master";

export const SIZE_BOARD = 800;
export const SIZE_BLOCK = SIZE_BOARD / 3;
export const SIZE_CELL = SIZE_BOARD / 9;
export const SIZE_CANDIDATE = SIZE_BOARD / 27;
export const COLOR_CELL_BORDER =  hexToInt(colors.gray[700]);
export const COLOR_BLOCK_BORDER =  hexToInt(colors.gray[700]);
export const COLOR_CELL =  hexToInt(colors.gray[200]);
export const COLOR_CELL_SELECTED =  hexToInt(colors.green[200]);
export const COLOR_CELL_GIVEN =  hexToInt(colors.gray[400]);

export const COLOR_CELL_DIGIT =  colors.blue[900];
export const COLOR_CELL_DIGIT_INVALID =  colors.red[500];
export const COLOR_CANDIDATE_DIGIT =  colors.blue[900];
