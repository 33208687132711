import { EventBus } from "@/EventBus";
import { Client } from "colyseus.js";
import { makeAutoObservable } from "mobx";
import { Game } from "phaser";
import React from "react";
import { gameConfig } from "@/config/gameConfig";
import { sceneConfig } from "@/config/sceneConfig";
import { SceneStart } from "@/game/start/SceneStart";
import logger from "@/logger";

const store = makeAutoObservable(
  {
    initialized: false,

    avatarId: null,
    name: 'Han Solo',

    phaserGame: null,
    colyseusClient: null,

    room: null,
    allRooms: [],

    players: new Map(),

    fullscreen: false,
    showLobby: true,

    setAvatarId(value) {
      this.avatarId = value;
      this.send('avatarId', value);
      sessionStorage.setItem("avatarId", this.avatarId);
    },
    async init() {
      if (this.initialized) {
        return;
      }
      const config = gameConfig;
      config.scene = SceneStart;
      this.setPhaserGame(new Game(config));
      this.phaserGame.events.on("ready", () => this.phaserGame.input.enabled = false);
      this.setInitialized(true);
      this.colyseusClient = new Client(import.meta.env.VITE_WS_URL);
      this.lobby = await this.colyseusClient.joinOrCreate("lobby");
      this.lobby.onMessage("rooms", rooms => this.setAllRooms(rooms));
      this.lobby.onMessage("+", ([roomId, room]) => {
        const roomIndex = this.allRooms.findIndex((room) => room.roomId === roomId);
        if (roomIndex !== -1) {
          this.setAllRoomsIndex(roomIndex, room);
        } else {
          this.pushToAllRooms(room);
        }
      });
      this.lobby.onMessage("-", (roomId) => {
        this.setAllRooms(this.allRooms.filter((room) => room.roomId !== roomId));
      });
      if (sessionStorage.getItem("name")) {
        this.setName(sessionStorage.getItem("name"));
      }
      if (sessionStorage.getItem("avatarId")) {
        this.setAvatarId(sessionStorage.getItem("avatarId"));
      }
      const urlRoom = new URLSearchParams(window.location.search).get("room");
      const urlGame = new URLSearchParams(window.location.search).get("game");
      if (urlRoom) {
        this.joinRoomById(urlRoom);
      } else if (urlGame) {
        this.createRoom(urlGame);
      }
      EventBus.on("input", enabled => this.phaserGame.input.enabled = enabled);
      this.setInitialized(true);
    },
    adjustCameraZoom() {
      const game = this.phaserGame;
      if (!game) {
        return;
      }
      game.scale.resize(window.innerWidth, window.innerHeight);
      game.scene.scenes.forEach(scene => {
        if (!!scene.safeAreaHeight && !!scene.safeAreaWidth && !!scene.cameras.main) {
          const cam = scene.cameras.main;
          const zoom = Math.min(
            window.innerWidth / scene.safeAreaWidth,
            (window.innerHeight - 116) / (scene.safeAreaHeight)
          );
          cam.setZoom(zoom)
          cam.centerOn(scene.safeAreaWidth / 2, scene.safeAreaHeight / 2)
        }
      });
    },
    handleRoomJoined() {
      this.phaserGame.registry.set("room", this.room);
      this.room.onLeave(this.handleRoomLeave);
      this.room.onError(this.handleRoomError);
      this.send('name', this.name);
      this.send('avatarId', this.avatarId);
      this.room.state.players.onAdd(this.handlePlayerAdd);
      logger.debug(`join ${this.roomName} ${this.roomId} ${this.sessionId}`);
      const sceneConfigEntry = sceneConfig[this.roomName];
      if (this.phaserGame.scene.keys[this.roomName]) {
        this.phaserGame.scene.keys[this.roomName].scene.start();
      } else {
        this.phaserGame.scene.add(this.roomName, sceneConfigEntry.class, true);
      }
      this.phaserGame.scene.keys['start'].scene.stop();
    },
    handleRoomLeave() {
      this.phaserGame.scene.stop(this.roomName);
      this.players.clear();
      this.setRoom(null);
      // this.phaserGame.scene.keys['start'].scene.start();
    },
    handlePlayerAdd(player) {
      player.onChange(() => this.handlePlayerChange(player));
      player.onRemove(() => this.handlePlayerOnRemove(player));
      this.handlePlayerChange(player);
    },
    handlePlayerChange(player) {
      this.players.set(player.number, {
        avatarId: player.avatarId,
        name: player.name,
      });
    },
    handlePlayerOnRemove(player) {
      this.players.delete(player.number);
    },

    handleRoomError(code, message) {
      logger.error(`Room error code: ${code}, message: ${message}`);
    },
    setRoom(value) {
      this.room = value;
    },
    send(type, message) {
      if (!this.room) {
        return;
      }
      this.room.send(type, message);
    },
    setInitialized(value) {
      this.initialized = value;
    },
    pushToAllRooms(room) {
      this.allRooms.push(room);
    },
    setAllRoomsIndex(index, value) {
      this.allRooms[index] = value;
    },
    setAllRooms(value) {
      this.allRooms = value;
    },
    async createRoom(roomName) {
      if (this.room) {
        await this.leaveRoom();
      }
      try {
        this.setRoom(await this.colyseusClient.create(roomName));
        this.handleRoomJoined();
      } catch (e) {
        logger.info(e.code, e.message);
        this.setRoom(null);
      }
    },
    async joinRoom(roomName) {
      if (this.room) {
        await this.leaveRoom();
      }
      try {
        this.setRoom(await this.colyseusClient.join(roomName));
        this.handleRoomJoined();
      } catch (e) {
        logger.info(e.code, e.message);
        this.setRoom(null);
      }
    },
    async joinOrCreateRoom(roomName) {
      if (this.room) {
        await this.leaveRoom();
      }
      try {
        this.setRoom(await this.colyseusClient.joinOrCreate(roomName));
        this.handleRoomJoined();
      } catch (e) {
        logger.info(e.code, e.message);
        this.setRoom(null);
      }
    },
    async joinRoomById(roomId) {
      if (this.roomId == roomId) {
        return;
      }
      if (this.room) {
        await this.leaveRoom();
      }
      try {
        this.setRoom(await this.colyseusClient.joinById(roomId));
        this.handleRoomJoined();
      } catch (e) {
        logger.info(e.code, e.message);
      }
    },
    async leaveRoom() {
      if (!this.room) {
        return;
      }
      await this.room.leave();
    },
    get roomName() {
      return this.room?.name;
    },
    get roomId() {
      return this.room?.roomId;
    },
    get sessionId() {
      return this.room?.sessionId;
    },
    setName(value) {
      this.name = value;
      this.send('name', value);
      sessionStorage.setItem("name", this.name);
    },
    setShowLobby(value) {
      this.showLobby = value;
      if (this.phaserGame) {
        this.phaserGame.input.enabled = !value;
      }
    },
    setPhaserGame(value) {
      this.phaserGame = value;
    },
    setFullscreen(value) {
      this.fullscreen = value;
    }
  },
  {},
  {autoBind: true}
);


const StoreContext = React.createContext(store);
export default () => React.useContext(StoreContext);
