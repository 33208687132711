import ButtonReset from "@/components/ButtonReset";
import ButtonConfirm from "@/components/buttons/ButtonConfirm";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Avatar from "@/components/Avatar";
import ButtonFullscreen from "@/components/ButtonFullscreen";
import ButtonLobby from "@/components/ButtonLobby";
import Lobby from "@/components/Lobby";
import { EventBus } from "@/EventBus";
import Overlay from "@/Overlay";
import useGameStore from "@/store/useGameStore";

const App = observer(() => {
  const {
    room,
    players,
    adjustCameraZoom,
    init,
    setFullscreen,
    showLobby,
  } = useGameStore();

  const phaserGameRef = useRef(null);
  const handle = useFullScreenHandle();

  const handleWindowResize = () => adjustCameraZoom(phaserGameRef.current);
  useEffect(() => {
    init();
    EventBus.on('window-resize', handleWindowResize);
    window.addEventListener('resize', handleWindowResize)
    window.onresize = handleWindowResize;
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    }
  }, []);
  useEffect(() => {
    EventBus.on('enter-fullscreen', handle.enter);
    EventBus.on('exit-fullscreen', handle.exit);
    return () => {
      EventBus.off('enter-fullscreen', handle.enter);
      EventBus.off('exit-fullscreen', handle.exit);
    }
  }, [handle.enter, handle.exit]);
  const playerOne = players.has(1) ? players.get(1) : null;
  const playerTwo = players.has(2) ? players.get(2) : null;

  return (
    <FullScreen
      handle={handle}
      onChange={state => setFullscreen(state)}
    >
      <div
        className="fixed top-0 bottom-0 left-0 right-0"
        id="game-container"
      />
      <div className="absolute px-1 top-1 w-full flex justify-between gap-1">
        <div className="flex-1 flex items-center gap-1">
          {
            !!playerOne && (
              <>
                1
                <Avatar
                  avatarId={playerOne.avatarId ? playerOne.avatarId : playerOne.name}
                  size={32}
                />
                {playerOne.name}
              </>
            )
          }
        </div>
        <div className="flex-1 flex justify-end items-center gap-1">
          {
            !!playerTwo && (
              <>
                {playerTwo.name}
                <Avatar
                  avatarId={playerTwo.avatarId ? playerTwo.avatarId : playerTwo.name}
                  size={32}
                />

                2
              </>
            )
          }
        </div>
      </div>

      <Overlay/>

      <div className={classNames("absolute bottom-1 left-1/2 -translate-x-1/2 flex gap-1", {
        hidden: false
      })}
      >
        {(!!room && !showLobby) && <ButtonReset/>}
        <ButtonLobby/>
        <ButtonFullscreen/>
      </div>
      <Lobby/>
      <ConfirmModal/>
    </FullScreen>
  )
})

export default App
