import { SceneChess } from "@/game/chess/SceneChess";
import { SceneConnectFour } from "@/game/connectFour/SceneConnectFour";
import ScenePinball from "@/game/pinball/ScenePinball";
import { SceneSudoku } from "@/game/sudoku/SceneSudoku";
import { SceneTicTacToe } from "@/game/ticTacToe/SceneTicTacToe";
import { SceneTopDownCar } from "@/game/topDownCar/SceneTopDownCar";

export const sceneConfig = {
  connectFour: {
    name: 'Vier Gewinnt',
    class: SceneConnectFour
  },
  ticTacToe: {
    name: 'Tic-Tac-Toe',
    class: SceneTicTacToe,
  },
  sudoku: {
    name: 'Sudoku',
    class: SceneSudoku,
  },
  chess: {
    name: 'Schach',
    class: SceneChess,
  },

  // tutorial: {
  //   name: 'Tutorial',
  //   class: SceneTutorial,
  // },
  // topDownCar: {
  //   name: 'Top Down Car',
  //   class: SceneTopDownCar,
  // },
  // pinball: {
  //   name: 'Pinball',
  //   class: ScenePinball,
  // },
}

