import { EventBus } from "@/EventBus";
import { makeAutoObservable } from "mobx";
import { createContext, useContext } from 'react';

const store = makeAutoObservable({
  modalConfirmOpen: false,
  modalConfirmButtonText: '',
  closeModal() {
    Object.assign(this, {
      modalConfirmOpen: false,
    })
  },
  modalConfirmFunction: () => {
  },
  closeModalConfirm() {
    this.modalConfirmOpen = false;
    EventBus.emit('input', true);
  },
  setModalConfirmFunction(value) {
    this.modalConfirmFunction = value;
  },
  setModalConfirmButtonText(value) {
    this.modalConfirmButtonText = value;
  },
  openModalConfirm() {
    this.modalConfirmOpen = true;
    EventBus.emit('input', false);
  },
}, {}, {autoBind: true})
const StoreContext = createContext(store);

export default () => useContext(StoreContext);
