import { GameObjects } from "phaser";
import { generate, solve } from "sudoku-core";
import { Block } from "@/game/sudoku/Block";
import { ButtonDigit } from "@/game/sudoku/ButtonDigit";
import { ButtonModeCandidate } from "@/game/sudoku/ButtonModeCandidate";
import { Cell } from "@/game/sudoku/Cell";
import { DIFFICULTY_EASY, SIZE_BOARD, SIZE_CELL } from "@/game/sudoku/const";
import { findPositionBlockX, findPositionBlockY, findPositionCellX, findPositionCellY } from "@/game/sudoku/util";
import logger from "@/logger";


export class Puzzle extends GameObjects.Container {
  difficulty = DIFFICULTY_EASY;
  board;
  boardSolution;
  boardUser;
  cellList = new Array(81).fill(null);
  selectedCellIndex = null;
  buttonDigitList = new Array(9).fill(null);
  buttonModeCandidate;
  buttonHint;
  selectedDigit = null;
  modeCandidate = false;
  room;

  constructor(scene, x, y) {
    super(scene, x, y);
    this.room = scene.registry.get('room');
    for (let i = 0; i < 9 * 9; i++) {
      const cell = new Cell(this.scene, findPositionCellX(i), findPositionCellY(i), i)
      this.cellList[i] = cell;
      this.add(cell);
    }
    for (let i = 0; i < 9; i++) {
      const block = new Block(this.scene, findPositionBlockX(i), findPositionBlockY(i));
      this.add(block);
    }

    // this.generate();
    // EventBus.on('sudoku-generate', difficulty => {
    //   this.generate(difficulty)
    // });
    this.syncBoard();
    this.addButtons();
    scene.add.existing(this);
    this.room.state.board.onChange((value, key) => {
      this.syncBoard();
    })
    this.room.state.boardUser.onChange((value, key) => {
      this.syncBoard();
    })
    this.room.state.boardSolution.onChange((value, key) => {
      this.syncBoard();
    })
  }

  generate(difficulty) {
    if (difficulty) {
      this.difficulty = difficulty;
    }
    this.board = generate(this.difficulty);
    const solveResult = solve(this.board);
    this.boardSolution = solveResult.board;
    this.boardUser = new Array(9 * 9).fill(null);
    // this.logger()
    this.syncBoard();
  }

  hint() {
    this.room.send('hint');
  }

  syncBoard() {
    for (let i = 0; i < 9 * 9; i++) {
      const cell = this.cellList[i];
      cell.reset();
      this.syncCell(i);
    }
  }

  addButtons() {
    for (let i = 0; i < 9; i++) {
      this.buttonDigitList[i] = new ButtonDigit(this.scene, i * SIZE_CELL, SIZE_BOARD + 1.5 * SIZE_CELL, i + 1);
    }
    this.buttonModeCandidate = new ButtonModeCandidate(this.scene, 0, SIZE_BOARD + 3 * SIZE_CELL);
    this.buttonHint = new ButtonDigit(this.scene, SIZE_CELL * 8, SIZE_BOARD + 3 * SIZE_CELL, '?', 'buttonHint');
  }

  setModeCandidate(modeCandidate) {
    this.modeCandidate = modeCandidate;
    for (let i = 0; i < 9; i++) {
      this.buttonModeCandidate.setSelected(modeCandidate);
      this.buttonDigitList[i].setModeCandidate(modeCandidate);
    }
  }

  setCandidate(indexCell, digit, value = null) {
    const cell = this.cellList[indexCell];
    cell.setCandidate(digit, value);
  }

  syncCell(index) {
    const cell = this.cellList[index];
    if (this.room.state.board[index]) {
      cell.setGiven(true);
      cell.setValid(true);
      cell.setDigit(this.room.state.board[index]);
    } else {
      const digitUser = this.room.state.boardUser[index];
      const digitSolution = this.room.state.boardSolution[index];
      cell.setValid(digitUser === digitSolution);
      cell.setGiven(false);
      cell.setDigit(digitUser);
    }
  }

  selectCell(index) {
    if (this.selectedDigit && !this.room.state.board[index] && !this.modeCandidate) {
      const data = {
        index,
        digit: this.selectedDigit
      }
      this.room.send('select-digit', data);
      // this.boardUser[index] = this.boardUser[index] !== this.selectedDigit ? this.selectedDigit : null;
    }

    if (this.selectedDigit && !this.room.state.board[index] && this.modeCandidate) {
      this.setCandidate(index, this.selectedDigit);
    }

    this.syncCell(index);
  }

  selectDigit(digit) {
    if (digit === this.selectedDigit) {
      this.buttonDigitList[digit - 1].setSelected(false);
      this.selectedDigit = null;
    } else {
      if (this.selectedDigit) {
        this.buttonDigitList[this.selectedDigit - 1].setSelected(false);
      }
      this.buttonDigitList[digit - 1].setSelected(true);
      this.selectedDigit = digit;
    }
  }

  log() {
    logger.info('board', this.board);
    logger.info('boardSolution', this.boardSolution);
    logger.info(this.boardUser);
  }


}


// const board = generate("easy");
// const solveResult = solve(board);
// const hintResult = hint(board);
// const analyzeResult = analyze(board);
// console.logger(board);
// console.logger(solveResult);
// console.logger(hintResult);
// console.logger(analyzeResult);

