import { observer } from "mobx-react-lite";
import { useState } from "react";
import Avatar from "@/components/Avatar";
import useGameStore from "@/store/useGameStore";
import useWindowSize from "@/util/useWindowSize";

const Profile = observer(() => {
  const [rot, setRot] = useState(0);

  const {
    avatarId,
    name,
    setAvatarId,
    setName,
  } = useGameStore();

  const {breakPoint} = useWindowSize();
  const size = {
    sm: 60,
    md: 70,
    lg: 80,
    xl: 90,
    '2xl': 100,
  }[breakPoint];
  const handleAvaterChange = () => {
    setAvatarId(crypto.randomUUID());
    setRot(rot + 20);
  }
  return (
    <div className="flex flex-col gap-1 items-center">

      <div className="flex items-end">
        <Avatar
          avatarId={avatarId ? avatarId : name}
          size={size}
        />
        <div onClick={handleAvaterChange}>
          <svg
            className="fill-primary mb-1"
            height={20}
            style={{transform: `rotate(${rot}deg)`}}
            version="1.1"
            viewBox="0 0 9.8142 9.8427"
            width={20}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m8.3822 8.39a4.9107 4.9107 0 0 0-2.9686-8.3502 0.38365 0.38365 0 0 0-0.29633 0.0979 0.36777 0.36777 0 0 0-0.12436 0.28575v0.045a0.381 0.381 0 0 0 0.34132 0.37835 4.101 4.101 0 0 1 2.4897 6.985l-0.0926 0.0926a0.15875 0.15875 0 0 1-0.2249 0l-0.8546-0.8546a0.15875 0.15875 0 0 0-0.26458 0.11113v2.503a0.15875 0.15875 0 0 0 0.15875 0.15875h2.5056a0.15346 0.15346 0 0 0 0.14552-0.0979 0.15081 0.15081 0 0 0-0.0344-0.17197l-0.87312-0.86783a0.15875 0.15875 0 0 1 0-0.22225zm-7.5671-3.4687a4.0852 4.0852 0 0 1 1.188-2.9104l0.0926-0.09a0.15346 0.15346 0 0 1 0.22225 0l0.85725 0.85461a0.15875 0.15875 0 0 0 0.26459-0.11113v-2.5056a0.15875 0.15875 0 0 0-0.15875-0.15875h-2.4897a0.15346 0.15346 0 0 0-0.14552 0.0979 0.15081 0.15081 0 0 0 0.0344 0.17198l0.85725 0.85725a0.15875 0.15875 0 0 1 0 0.22225l-0.0926 0.0926a4.908 4.908 0 0 0 2.9686 8.3608 0.38629 0.38629 0 0 0 0.42069-0.38365v-0.0423a0.381 0.381 0 0 0-0.34131-0.381 4.1037 4.1037 0 0 1-3.6777-4.0746z"
            />
          </svg>
        </div>
      </div>

      <input
        className="px-2 w-full input input-bordered pointer-events-auto"
        maxLength={14}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        type="text"
        value={name}
      />

    </div>
  )
})

export default Profile;
